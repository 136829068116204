import Vue from 'vue';
import AlertSuccess from "@/components/custom/AlertSuccess";
import AlertError from "@/components/custom/AlertError";
import AlertConfirmation from "@/components/custom/AlertConfirmation";
import FormModal from "@/components/custom/FormModal";
import TableModal from "@/components/custom/TableModal";
import ErrorMessage from "@/components/custom/ErrorMessage";

export default () => {
    // register globally
    Vue.component('AlertSuccess', AlertSuccess);
    Vue.component('AlertError', AlertError);
    Vue.component('AlertConfirmation', AlertConfirmation);
    Vue.component('FormModal', FormModal);
    Vue.component('TableModal', TableModal);
    Vue.component('ErrorMessage', ErrorMessage);
};