import Vue from "vue";
import Vuex from "vuex";
import VueCookies from "vue-cookies";

Vue.use(Vuex);
Vue.use(VueCookies);

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: Vue.$cookies.get('loggedIn') != undefined ? Vue.$cookies.get('loggedIn') : false,
      data: Vue.$cookies.get('userData') != undefined ? Vue.$cookies.get('userData') : [],
      uid: null,
      token: null,
    },
    funcionariosSearch: Vue.$cookies.get('funcionariosSearch') != undefined ? Vue.$cookies.get('funcionariosSearch') : [],
    empresaParceiraSearch: Vue.$cookies.get('empresaParceiraSearch') != undefined ? Vue.$cookies.get('empresaParceiraSearch') : [],
    produtosSearch: Vue.$cookies.get('produtosSearch') != undefined ? Vue.$cookies.get('produtosSearch') : [],
    linhasSearch: Vue.$cookies.get('linhasSearch') != undefined ? Vue.$cookies.get('linhasSearch') : [],
    especialidadeSearch: Vue.$cookies.get('especialidadeSearch') != undefined ? Vue.$cookies.get('especialidadeSearch') : [],
    tagsSearch: Vue.$cookies.get('tagsSearch') != undefined ? Vue.$cookies.get('tagsSearch') : [],
    categoriasSearch: Vue.$cookies.get('categoriasSearch') != undefined ? Vue.$cookies.get('categoriasSearch') : [],
    medicosSearch: Vue.$cookies.get('medicosSearch') != undefined ? Vue.$cookies.get('medicosSearch') : [],
    artigosSearch: Vue.$cookies.get('artigosSearch') != undefined ? Vue.$cookies.get('artigosSearch') : [],
    cartasSearch: Vue.$cookies.get('cartasSearch') != undefined ? Vue.$cookies.get('cartasSearch') : [],
    cartasSearchTR: Vue.$cookies.get('cartasSearchTR') != undefined ? Vue.$cookies.get('cartasSearchTR') : [],
    solicitacoesSearchIc: Vue.$cookies.get('solicitacoesSearchIc') != undefined ? Vue.$cookies.get('solicitacoesSearchIc') : [],
    solicitacoesSearchTr: Vue.$cookies.get('solicitacoesSearchTr') != undefined ? Vue.$cookies.get('solicitacoesSearchTr') : [],
  },
  mutations: {
    SET_USER_DATA(state, payload) {
      delete payload.funcionario.appToken
      state.user.data = payload
      Vue.$cookies.set('userData', state.user.data);
    },
    SET_USER_LOOGEDIN(state, payload) {
      state.user.loggedIn = payload
      Vue.$cookies.set('loggedIn', state.user.loggedIn);
    },
    SET_FUNCIONARIOS_SEARCH(state, payload) {
      state.funcionariosSearch = []
      state.funcionariosSearch.push(payload)
      Vue.$cookies.set('funcionariosSearch', state.funcionariosSearch);
    },
    SET_EMPRESA_PARCEIRA_SEARCH(state, payload) {
      state.empresaParceiraSearch = []
      state.empresaParceiraSearch.push(payload)
      Vue.$cookies.set('funcionariosSearch', state.empresaParceiraSearch);
    },
    SET_PRODUTOS_SEARCH(state, payload) {
      state.produtosSearch = []
      state.produtosSearch.push(payload)
      Vue.$cookies.set('produtosSearch', state.produtosSearch);
    },
    SET_LINHAS_PRODUTOS_SEARCH(state, payload) {
      state.linhasSearch = []
      state.linhasSearch.push(payload)
      Vue.$cookies.set('linhasSearch', state.linhasSearch);
    },
    SET_ESPECIALIDADES_SEARCH(state, payload) {
      state.especialidadeSearch = []
      state.especialidadeSearch.push(payload)
      Vue.$cookies.set('especialidadeSearch', state.especialidadeSearch);
    },
    SET_TAGS_SEARCH(state, payload) {
      state.tagsSearch = []
      state.tagsSearch.push(payload)
      Vue.$cookies.set('tagsSearch', state.tagsSearch);
    },
    SET_CATEGORIAS_SEARCH(state, payload) {
      state.categoriasSearch = []
      state.categoriasSearch.push(payload)
      Vue.$cookies.set('categoriasSearch', state.categoriasSearch);
    },
    SET_MEDICOS_SEARCH(state, payload) {
      state.medicosSearch = []
      state.medicosSearch.push(payload)
      Vue.$cookies.set('medicosSearch', state.medicosSearch);
    },
    SET_ARTIGOS_SEARCH(state, payload) {
      state.artigosSearch = []
      state.artigosSearch.push(payload)
      Vue.$cookies.set('artigosSearch', state.artigosSearch);
    },
    SET_CARTAS_SEARCH(state, payload) {
      state.cartasSearch = []
      state.cartasSearch.push(payload)
      Vue.$cookies.set('cartasSearch', state.cartasSearch);
    },
    SET_CARTAS_SEARCH_TR(state, payload) {
      state.cartasSearchTR = []
      state.cartasSearchTR.push(payload)
      Vue.$cookies.set('cartasSearchTR', state.cartasSearchTR);
    },
    SET_SOLICITACOES_SEARCH_IC(state, payload) {
      state.solicitacoesSearchIc = []
      state.solicitacoesSearchIc.push(payload)
      Vue.$cookies.set('solicitacoesSearchIc', state.solicitacoesSearchIc);
    },
    SET_SOLICITACOES_SEARCH_TR(state, payload) {
      state.solicitacoesSearchTr = []
      state.solicitacoesSearchTr.push(payload)
      Vue.$cookies.set('solicitacoesSearchTr', state.solicitacoesSearchTr);
    }
  },
  actions: {
    setUserData(context, payload) {
      context.commit("SET_USER_DATA", payload);
    },
    setUserLoggedIn(context, payload) {
      context.commit("SET_USER_LOOGEDIN", payload);
    },
    setFuncionariosSearch(context, payload) {
      context.commit("SET_FUNCIONARIOS_SEARCH", payload)
    },
    setEmpresaParceiraSearch(context, payload) {
      context.commit("SET_EMPRESA_PARCEIRA_SEARCH", payload)
    },
    setProdutosSearch(context, payload) {
      context.commit("SET_PRODUTOS_SEARCH", payload)
    },
    setLinhasProdutosSearch(context, payload) {
      context.commit("SET_LINHAS_PRODUTOS_SEARCH", payload)
    },
    setEspecialidadesSearch(context, payload) {
      context.commit("SET_ESPECIALIDADES_SEARCH", payload)
    },
    setTagsSearch(context, payload) {
      context.commit("SET_TAGS_SEARCH", payload)
    },
    setCategoriasSearch(context, payload) {
      context.commit("SET_CATEGORIAS_SEARCH", payload)
    },
    setMedicosSearch(context, payload) {
      context.commit("SET_MEDICOS_SEARCH", payload)
    },
    setArtigosSearch(context, payload) {
      context.commit("SET_ARTIGOS_SEARCH", payload)
    },
    setCartasSearch(context, payload) {
      context.commit("SET_CARTAS_SEARCH", payload)
    },
    setCartasSearchTr(context, payload) {
      context.commit("SET_CARTAS_SEARCH_TR", payload)
    },
    setSolicitacoesSearchIc(context, payload) {
      context.commit("SET_SOLICITACOES_SEARCH_IC", payload)
    },
    setSolicitacoesSearchTr(context, payload) {
      context.commit("SET_SOLICITACOES_SEARCH_TR", payload)
    }
  },
  modules: {},
});
