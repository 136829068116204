<template>
  <div class="d-flex pa-8 text-center flex-column">
    <v-icon x-large color="red" class="mb-4">mdi-alert-circle-outline</v-icon>
    <h3>{{ message }}</h3>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>
