import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import ComponentRegistration from "@/utils/component-registration";
import { VTreeSelect } from "vuetify-toolkit/vuetify-toolkit.umd";

Vue.use(VueMask);
ComponentRegistration();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  VTreeSelect,
  render: (h) => h(App),
}).$mount("#app");
