<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <div class="mb-2 text-center">{{ dialogMessage }}</div>
      </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="red" outlined @click="$emit('close')">{{ btnLabelCancel }}</v-btn>
        <v-btn
          color="green"
          outlined
          @click="$emit('accept')"
          :loading="loading"
          >{{ btnLabelSend }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FormModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dialogMessage: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    btnLabelSend: {
      type: String,
      default: 'Enviar',
    },
    
    btnLabelCancel: {
      type: String,
      default: 'Cancelar',
    },
  },
};
</script>
