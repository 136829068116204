import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "Default" */ '@/layouts/Default.vue'),
    meta: { requiresLogin: true },
    children: [
      {
        path: '/',
        name: 'Solicitacoes',
        meta: { permission: "solicitacao.buscar" },
        component: () => import(/* webpackChunkName: "Solicitacoes" */ '@/views/Solicitacoes.vue'),
      },
      {
        path: '/treinamento',
        name: 'SolicitacoesTreinamento',
        meta: { permission: "solicitacao.buscar" },
        component: () => import(/* webpackChunkName: "SolicitacoesTreinamento" */ '@/views/SolicitacoesTreinamento.vue'),
      },
      {
        path: '/solicitacao-criar',
        name: 'SolicitacoesCriar',
        meta: { permission: "solicitacao.criar" },
        component: () => import(/* webpackChunkName: "SolicitacoesCriar" */ '@/views/SolicitacoesCriar.vue'),
      },
      {
        path: '/treinamento-criar',
        name: 'SolicitacoesTreinamentoCriar',
        meta: { permission: "solicitacao.criarParaTreinamentos" },
        component: () => import(/* webpackChunkName: "SolicitacoesTreinamentoCriar" */ '@/views/SolicitacoesTreinamentoCriar.vue'),
      },
      {
        path: '/solicitacao-editar/:id',
        name: 'SolicitacoesEditar',
        meta: { permission: "solicitacao.editar" },
        component: () => import(/* webpackChunkName: "SolicitacoesEditar" */ '@/views/SolicitacoesEditar.vue'),
      },
      {
        path: '/treinamento-editar/:id',
        name: 'SolicitacoesTreinamentoEditar',
        meta: { permission: "solicitacao.editarParaTreinamentos" },
        component: () => import(/* webpackChunkName: "SolicitacoesTreinamentoEditar" */ '@/views/SolicitacoesTreinamentoEditar.vue'),
      },
      {
        path: '/visualizar-atendimento/:id',
        name: 'SolicitacaoVisualizarAtendimento',
        meta: { permission: "solicitacao.exibir" },
        component: () => import(/* webpackChunkName: "SolicitacaoVisualizarAtendimento" */ '@/views/SolicitacaoVisualizarAtendimento.vue'),
      },
      {
        path: '/criar-carta-resposta/:id',
        name: 'CartasRespostaCriar',
        meta: { permission: "solicitacao.exibir" },
        component: () => import(/* webpackChunkName: "CartasRespostaCriar" */ '@/views/CartasRespostaCriar.vue'),
      },
      {
        path: '/editar-carta-resposta/:id',
        name: 'CartasRespostaEditar',
        meta: { permission: "solicitacao.exibir" },
        component: () => import(/* webpackChunkName: "CartasRespostaEditar" */ '@/views/CartasRespostaEditar.vue'),
      },
      {
        path: 'funcionarios',
        name: 'Funcionarios',
        meta: { permission: "funcionario.buscar" },
        component: () => import(/* webpackChunkName: "Funcionarios" */ '@/views/Funcionarios.vue'),
      },
      {
        path: 'funcionario/:id',
        name: 'FuncionarioEditar',
        meta: { permission: "funcionario.editar" },
        component: () => import(/* webpackChunkName: "FuncionarioEditar" */ '@/views/FuncionarioEditar.vue'),
      },
      {
        path: 'empresas-parceiras',
        name: 'EmpresasParceiras',
        meta: { permission: "empresaParceira.buscar" },
        component: () => import(/* webpackChunkName: "EmpresasParceiras" */ '@/views/EmpresasParceiras.vue'),
      },
      {
        path: 'empresa-parceira-criar',
        name: 'EmpresaParceiraCriar',
        meta: { permission: "empresaParceira.criar" },
        component: () => import(/* webpackChunkName: "EmpresaParceiraCriar" */ '@/views/EmpresaParceiraCriar.vue'),
      },
      {
        path: 'empresa-parceira-editar/:id',
        name: 'EmpresaParceiraEditar',
        meta: { permission: "empresaParceira.editar" },
        component: () => import(/* webpackChunkName: "EmpresaParceiraEditar" */ '@/views/EmpresaParceiraEditar.vue'),
      },
      {
        path: 'produtos',
        name: 'Produtos',
        meta: { permission: "produto.buscar" },
        component: () => import(/* webpackChunkName: "Produtos" */ '@/views/Produtos.vue'),
      },
      {
        path: 'protudo-criar',
        name: 'ProdutoCriar',
        meta: { permission: "produto.criar" },
        component: () => import(/* webpackChunkName: "ProdutoCriar" */ '@/views/ProdutoCriar.vue'),
      },
      {
        path: 'protudo/:id',
        name: 'ProdutoEditar',
        meta: { permission: "produto.editar" },
        component: () => import(/* webpackChunkName: "ProdutoEditar" */ '@/views/ProdutoEditar.vue'),
      },
      {
        path: 'cadastro-linha',
        name: 'LinhasDeProdutos',
        meta: { permission: "linha.buscar" },
        component: () => import(/* webpackChunkName: "LinhasDeProdutos" */ '@/views/LinhasDeProdutos.vue'),
      },
      {
        path: 'criar-linha-produtos',
        name: 'LinhasDeProdutosCriar',
        meta: { permission: "linha.criar" },
        component: () => import(/* webpackChunkName: "LinhasDeProdutosCriar" */ '@/views/LinhasDeProdutosCriar.vue'),
      },
      {
        path: 'editar-linha-produtos/:id',
        name: 'LinhasDeProdutosEditar',
        meta: { permission: "linha.editar" },
        component: () => import(/* webpackChunkName: "LinhasDeProdutosEditar" */ '@/views/LinhasDeProdutosEditar.vue'),
      },
      {
        path: 'especialidades',
        name: 'Especialidades',
        meta: { permission: "especialidadeMedica.buscar" },
        component: () => import(/* webpackChunkName: "Especialidades" */ '@/views/Especialidades.vue'),
      },
      {
        path: 'especialidade-criar',
        name: 'EspecialidadeCriar',
        meta: { permission: "especialidadeMedica.criar" },
        component: () => import(/* webpackChunkName: "EspecialidadeCriar" */ '@/views/EspecialidadeCriar.vue'),
      },
      {
        path: 'especialidade-editar/:id',
        name: 'EspecialidadeEditar',
        meta: { permission: "especialidadeMedica.editar" },
        component: () => import(/* webpackChunkName: "EspecialidadeEditar" */ '@/views/EspecialidadeEditar.vue'),
      },
      {
        path: 'tags',
        name: 'Tags',
        meta: { permission: "tag.buscar" },
        component: () => import(/* webpackChunkName: "Tags" */ '@/views/Tags.vue'),
      },
      {
        path: 'tags-criar',
        name: 'TagsCriar',
        meta: { permission: "tag.criar" },
        component: () => import(/* webpackChunkName: "TagsCriar" */ '@/views/TagsCriar.vue'),
      },
      {
        path: 'tags-editar/:id',
        name: 'TagsEditar',
        meta: { permission: "tag.editar" },
        component: () => import(/* webpackChunkName: "TagsEditar" */ '@/views/TagsEditar.vue'),
      },
      {
        path: 'categorias',
        name: 'Categorias',
        meta: { permission: "categoria.buscar" },
        component: () => import(/* webpackChunkName: "Categorias" */ '@/views/Categorias.vue'),
      },
      {
        path: 'categorias-criar',
        name: 'CategoriasCriar',
        meta: { permission: "categoria.criar" },
        component: () => import(/* webpackChunkName: "CategoriasCriar" */ '@/views/CategoriasCriar.vue'),
      },
      {
        path: 'categorias-editar/:id',
        name: 'CategoriasEditar',
        meta: { permission: "categoria.editar" },
        component: () => import(/* webpackChunkName: "CategoriasEditar" */ '@/views/CategoriasEditar.vue'),
      },
      {
        path: 'medicos-solicitantes',
        name: 'MedicosSolicitantes',
        meta: { permission: "medicoSolicitante.buscar" },
        component: () => import(/* webpackChunkName: "MedicosSolicitantes" */ '@/views/MedicosSolicitantes.vue'),
      },
      {
        path: 'medico-solicitante-editar/:id',
        name: 'MedicoSolicitanteEditar',
        meta: { permission: "medicoSolicitante.editar" },
        component: () => import(/* webpackChunkName: "MedicosSolicitantesEditar" */ '@/views/MedicoSolicitanteEditar.vue'),
      },
      {
        path: 'medico-solicitante-visualizar/:id',
        name: 'MedicoSolicitanteVisualizar',
        meta: { permission: "medicoSolicitante.exibir" },
        component: () => import(/* webpackChunkName: "MedicoSolicitanteVisualizar" */ '@/views/MedicoSolicitanteVisualizar.vue'),
      },
      {
        path: 'medico-solicitante-criar',
        name: 'MedicoSolicitanteCriar',
        meta: { permission: "medicoSolicitante.criar" },
        component: () => import(/* webpackChunkName: "MedicosSolicitantesCriar" */ '@/views/MedicoSolicitanteCriar.vue'),
      },
      {
        path: 'configuracoes',
        name: 'Configuracoes',
        meta: { permission: "config.exibir" },
        component: () => import(/* webpackChunkName: "Configuracoes" */ '@/views/Configuracoes.vue'),
      },
      {
        path: 'artigos',
        name: 'Artigos',
        meta: { permission: "artigo.buscar" },
        component: () => import(/* webpackChunkName: "Artigos" */ '@/views/Artigos.vue'),
      },
      {
        path: 'artigo-criar',
        name: 'ArtigoCriar',
        meta: { permission: "artigo.criar" },
        component: () => import(/* webpackChunkName: "ArtigoCriar" */ '@/views/ArtigoCriar.vue'),
      },
      {
        path: 'artigo-editar/:id',
        name: 'ArtigoEditar',
        meta: { permission: "artigo.editar" },
        component: () => import(/* webpackChunkName: "ArtigoEditar" */ '@/views/ArtigoEditar.vue'),
      },
      {
        path: 'banco-de-cartas-ic',
        name: 'BancoDeCartasIC',
        meta: { permission: "carta.buscar" },
        component: () => import(/* webpackChunkName: "BancoDeCartasIC" */ '@/views/BancoDeCartasIC.vue'),
      },
      {
        path: 'banco-de-cartas-ic-editar/:id',
        name: 'BancoDeCartasICEditar',
        meta: { permission: "carta.editar" },
        component: () => import(/* webpackChunkName: "BancoDeCartasICEditar" */ '@/views/BancoDeCartasICEditar.vue'),
      },
      {
        path: 'banco-de-cartas-ic-visualizar/:id',
        name: 'BancoDeCartasICVisualizar',
        meta: { permission: "carta.exibir" },
        component: () => import(/* webpackChunkName: "BancoDeCartasICVisualizar" */ '@/views/BancoDeCartasICVisualizar.vue'),
      },
      {
        path: 'banco-de-cartas-ic-criar',
        name: 'BancoDeCartasICCriar',
        meta: { permission: "carta.criar" },
        component: () => import(/* webpackChunkName: "BancoDeCartasICCriar" */ '@/views/BancoDeCartasICCriar.vue'),
      },
      {
        path: 'banco-de-cartas-tr',
        name: 'BancoDeCartasTR',
        meta: { permission: "carta.buscar" },
        component: () => import(/* webpackChunkName: "BancoDeCartasTR" */ '@/views/BancoDeCartasTR.vue'),
      },
      {
        path: 'banco-de-cartas-tr-criar',
        name: 'BancoDeCartasTRCriar',
        meta: { permission: "carta.criar" },
        component: () => import(/* webpackChunkName: "BancoDeCartasTRCriar" */ '@/views/BancoDeCartasTRCriar.vue'),
      },
      {
        path: 'banco-de-cartas-tr-visualizar/:id',
        name: 'BancoDeCartasTRVisualizar',
        meta: { permission: "carta.exibir" },
        component: () => import(/* webpackChunkName: "BancoDeCartasTRVisualizar" */ '@/views/BancoDeCartasTRVisualizar.vue'),
      },
      {
        path: 'banco-de-cartas-tr-editar/:id',
        name: 'BancoDeCartasTREditar',
        meta: { permission: "carta.editar" },
        component: () => import(/* webpackChunkName: "BancoDeCartasTREditar" */ '@/views/BancoDeCartasTREditar.vue'),
      },
      {
        path: 'relatorios/dashboard',
        name: 'RelatorioDashboard',
        meta: { permission: "relatorio.dashboard" },
        component: () => import(/* webpackChunkName: "RelatorioDashboard" */ '@/views/RelatorioDashboard.vue'),
      },
      {
        path: 'relatorios/exportar-solicitacoes',
        name: 'RelatorioExportarSolicitacoes',
        meta: { permission: "relatorio.dashboard" },
        component: () => import(/* webpackChunkName: "RelatorioExportarSolicitacoes" */ '@/views/RelatorioExportarSolicitacoes.vue'),
      },
      {
        path: 'relatorios/atendimentos-medico-aprovador',
        name: 'RelatorioAtendimentosMedicoAprovador',
        meta: { permission: "relatorio.dashboard" },
        component: () => import(/* webpackChunkName: "RelatorioAtendimentosMedicoAprovador" */ '@/views/RelatorioAtendimentosMedicoAprovador.vue'),
      },
      {
        path: 'relatorios/atendimentos-colaborador',
        name: 'RelatorioAtendimentosColaborador',
        meta: { permission: "relatorio.dashboard" },
        component: () => import(/* webpackChunkName: "RelatorioAtendimentosColaborador" */ '@/views/RelatorioAtendimentosColaborador.vue'),
      },
      {
        path: 'relatorios/solicitacoes-por-produtos',
        name: 'RelatorioSolicitacoesPorProduto',
        meta: { permission: "relatorio.dashboard" },
        component: () => import(/* webpackChunkName: "RelatorioSolicitacoesPorProduto" */ '@/views/RelatorioSolicitacoesPorProduto.vue'),
      },
      {
        path: 'relatorios/solicitacoes-por-linha',
        name: 'RelatorioSolicitacoesPorLinha',
        meta: { permission: "relatorio.dashboard" },
        component: () => import(/* webpackChunkName: "RelatorioSolicitacoesPorLinha" */ '@/views/RelatorioSolicitacoesPorLinha.vue'),
      },
    ]
  },
  {
    path: '/logincheck',
    name: 'Login',
    meta: { isLoged: true },
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue')
  },
  {
    path: "/sso",
    name: "LoginSSO",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/LoginSSO.vue"),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue'),
  },
  {
    path: '/painel',     
    component: () => import(/* webpackChunkName: "HomeMedico" */ '@/views/HomeMedico.vue'),
    children: [
      {
        path: 'meus-dados/:hash',
        name: 'MeusDados',
        component: () => import(/* webpackChunkName: "MeusDados" */ '@/views/MeusDados.vue'),
        meta: {titulo: {text: 'InfoFQM - Meus dados cadastrais'}}
      },
      {
        path: 'solicitacao/:hash',
        name: 'MinhaSolicitacao',
        component: () => import(/* webpackChunkName: "MeusDados" */ '@/views/MinhaSolicitacao.vue'),
        meta: {titulo: {text: 'InfoFQM - Resposta de Solicitação'}}
      },
      {
        path: 'solicitar-descadastro/:hash',
        name: 'SolicitarExclusao',
        component: () => import(/* webpackChunkName: "SolicitarExclusao" */ '@/views/SolicitarExclusao.vue'),
        meta: {titulo: {text: 'InfoFQM - Solicitar Descadastro'}}
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (to.matched.some((record) => record.meta.permission) && store.state.user.data.length) {
      store.state.user.data.permissoes.includes(to.meta.permission)
        ? next()
        : next({ path: "/" });
    } else {
      store.state.user.loggedIn ? next() : window.location = process.env.VUE_APP_LOGIN;
    }
  } else if (to.matched.some((record) => record.meta.isLoged)) {
    !store.state.user.loggedIn ? next() : next({ path: "/" });
  } else {
    next();
  }
});

export default router
